<template>
   <div>
      <v-data-table :headers="columns" :items="filteredRecords" dense
         :items-per-page="15" :footer-props="{'items-per-page-options': [15, 30, 60]}" :hide-default-footer="!records.length"
         :item-class="itemRowBackground"
         >
         <!-- :single-expand="false" show-expand -->
         <template v-slot:top>
            <v-toolbar flat>
               <v-text-field v-model="table.search" placeholder="Search" hide-details clearable single-line flat
                  prepend-inner-icon="mdi-magnify"
                  class="mr-3 hidden-sm-and-down" style="max-width: 240px;"
                  />
               <v-select v-model="table.filter" :items="registrations" placeholder="Approval Status" hide-details multiple clearable
                  :return-object="false" :menu-props="{ bottom: true, offsetY: true }"
                  class="mr-3 hidden-sm-and-down" style="max-width: 240px;"
                  >
                  <template v-slot:selection="{ item, index }">
                     <span v-if="index < 1">{{ index === 0 ? item.text : `, ${item.text}` }}</span>
                     <span v-if="index === 1" class="grey--text ml-2">
                        ( +{{ table.filter.length - 1 }} more )
                     </span>
                  </template>
               </v-select>
               <v-spacer />
               <!-- <v-btn v-if="$hasRole(activity.kind.toLowerCase(), 'I')" x-large icon tile color="accent" class="mx-1" :loading="$store.state.process === 'importActivity'" @click="$refs.xlsxImport.click()">
                  <v-icon size="48">
                     mdi-database-import
                  </v-icon>
                  <input ref="xlsxImport" type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" alt="xlsxImport" style="display:none;" @change="uploadXLS">
               </v-btn> -->
               <v-btn v-if="$hasRole(activity.kind.toLowerCase(), 'C')" x-large icon tile color="secondary" class="mx-1" @click="activity.id = 0">
                  <v-icon size="48">
                     mdi-plus-box
                  </v-icon>
               </v-btn>
               <!-- <v-btn v-if="$hasRole(activity.kind.toLowerCase(), 'E')" x-large icon tile color="accent" class="mx-1" @click="downloadXLS">
                  <v-icon size="48">
                     mdi-database-export
                  </v-icon>
               </v-btn> -->
            </v-toolbar>
            <v-divider />
         </template>
         <template v-slot:[`item.organization`]="{ item }">
            {{ organizationText(item.organization) }}
         </template>
         <template v-slot:[`item.floor`]="{ item }">
            {{ floorText(item.floor) }}
         </template>
         <template v-slot:[`item.since`]="{ item }">
            {{ item.Activities.find(j => ['Register', 'Enrolled'].includes(j.name)).since }}
         </template>
         <template v-slot:[`item.actions`]="{ item }">
            <v-icon v-if="$hasRole(item.kind.toLowerCase(), 'RU')" class="ml-2" @click="modifyRecord(item)">
               {{ $hasRole(item.kind.toLowerCase(), 'U') ? 'mdi-pencil' : 'mdi-eye' }}
            </v-icon>
            <v-icon v-if="$hasRole(item.kind.toLowerCase(), 'D')" class="ml-2" @click="removeRecord(item)">
               mdi-delete
            </v-icon>
         </template>
         <template v-slot:no-data>
            <div class="my-5" style="font-size: 1.15rem;">
               No data available
            </div>
         </template>
      </v-data-table>
      <v-dialog v-model="dialog.del" max-width="330px">
         <v-card>
            <v-list-item two-line class="grey lighten-2 px-6 py-2">
               <v-list-item-content dark>
                  <v-list-item-title>{{ `Delete item ${record.activity}?` }}</v-list-item-title>
                  <v-list-item-subtitle>{{ record.identity }}</v-list-item-subtitle>
               </v-list-item-content>
            </v-list-item>
            <v-card-actions class="px-6 py-4">
               <v-spacer />
               <v-btn color="grey darken-2" text class="px-4" @click="dialog.del = false">
                  Cancel
               </v-btn>
               <v-btn color="secondary" depressed class="px-4" @click="deleteRecord">
                  OK
               </v-btn>
            </v-card-actions>
         </v-card>
      </v-dialog>
   </div>
</template>

<script>
import { imageFromUrl } from '@/utils/nirtara' // , encrypt
import { approval } from '@/utils/fields.js'
export default {
   name: 'NApprovalTable',

   props: {
      activity: { type: Object },
      records: { type: Array },
      organizations: { type: Array },
      floors: { type: Array },
   },

   data: () => ({
      isUsed: false,
      columns: [
         // { text: 'ID', value: 'identity', width: 115 },
         { text: 'Visitor', value: 'name' },
         { text: 'Tenant', value: 'organization' },
         { text: 'Floor', value: 'floor', width: 150 },
         { text: 'Since', value: 'since', width: 170 },
         { text: 'Status', value: 'activity', width: 100 },
         { text: 'Code', value: 'identity', width: 150 },
         // { text: 'Unit', value: 'unit', sortable: false },
         // { text: 'Claimed', value: 'code', width: 110, sortable: false },
         // { text: '', value: 'data-table-expand', align: ' pl-2 pr-0' },
         { text: 'Actions', value: 'actions', width: 80, align: 'end pl-0', sortable: false },
      ],
      dialog: { del: false },
      table: { search: '', filter: [] },
      record: {},
      registrations: [],
   }),

   computed: {
      setUrlFromImage () {
         return image => imageFromUrl(image)
      },
      filteredRecords () {
         return this.records.filter((j) => {
            const search = this.table.search?.toLowerCase()
            var filteredName = j.name && j.name.toLowerCase().indexOf(search) !== -1
            var filteredSince = j.Activities[0].since && j.Activities[0].since.indexOf(search) !== -1
            return (!search || filteredName || filteredSince) &&
                   (!this.table.filter.length || this.table.filter.includes(j.activity))
         })
      },
      organizationText () {
         return organization => (this.organizations.find(j => j.identity === organization) || { name: organization }).name
      },
      floorText () {
         return floor => (this.floors.find(j => j.identity === floor) || { name: floor }).name
      },
   },
   created () {
      this.registrations = [
         { text: 'Enrolled' },
         { text: 'Register' },
         { text: 'Rejected' },
         { text: 'Accepted' },
      ]
   },
   mounted () {
      // console.log('mounted (records)', JSON.parse(JSON.stringify(this.records)), '\ntenants', JSON.parse(JSON.stringify(this.tenants)))
   },
   methods: {
      itemRowBackground: function (item) {
         switch (item.activity) {
            default: return item.activity === 'Enrolled' ? 'grey--text text--lighten-1' : ''
            // case 'Ammended':
            // case 'Accepted': return 'accent lighten-5'
            // case 'Verified': return 'amber lighten-5'
            // case '': return 'error lighten-5'
         }
      },
      modifyRecord (item) {
         Object.assign(this.activity, approval, item)
         this.$vuetify.goTo(0)
      },
      removeRecord (item) {
         this.record = Object.assign({}, { id: -1 }, item)
         this.dialog.del = true
      },
      deleteRecord () {
         this.$emit('delete', this.record)
         this.dialog.del = false
      },
   },
}
</script>

<style lang="scss" scoped>
::v-deep .v-data-table-header > tr > th span {
   font-size: 1.0rem;
   letter-spacing: 0;
   min-height: 60px;
}
::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
   font-size: 0.9rem;
}
::v-deep .v-data-footer,
::v-deep .v-data-footer__select .v-select__selections .v-select__selection--comma {
   font-size: 0.9rem;
}
::v-deep .v-list-item__action:first-child {
   margin-right: 16px;
}
::v-deep .v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
   -webkit-box-shadow: none;
   box-shadow: none;
}
</style>
